import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Which emails sparked joy – an animated timeline built with React and D3 tutorial",
  "description": "I asked thosands of people \"Did you like this email?\". Here's the result in a beautiful data visualization. Learn how to build and animate responsive data visualizations with React and D3.",
  "date": "2019-08-05T15:13:00.000Z",
  "published": "2019-05-05T15:13:00.000Z",
  "image": "../../images/articles/RP9ZY3C.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/iO6X09T.gif",
        "alt": "Which emails sparked joy?"
      }}></img></p>
    <p>{`Ever wondered if the emails you send spark joy? You can ask!`}</p>
    <p>{`About a year ago I started adding a little `}<em parentName="p">{`"Did you like this?"`}</em>{` form at the
bottom of emails sent to some 9,000 readers every week. The results have been
wonderful ❤️`}</p>
    <p>{`I now know what lands and what doesn't with my audience and it's made me a
better writer. Here's an example where I wrote the same message in 2 different
ways, sent to the same audience.`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`Two emails, same message, same audience, wildly different opinion results.`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`The power of writing 🤨 `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/media/EA5aACIVAAAKpKJ.jpg",
            "width": "50%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img><img parentName="div" {...{
            "src": "https://pbs.twimg.com/media/EA5aACaUYAE2-3A.jpg",
            "width": "50%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1156961114476797952"
          }}>{`4:13:19 PM – 8/1/2019`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1156961114476797952",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`5`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1156961114476797952",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`2`}</a></div>{`
    `}</div></div>
    <p>{`What a difference writing can make!`}</p>
    <p>{`You know what makes data like this even better? A data visualization. With
hearts and emojis and transitions and stuff!`}</p>
    <p>{`So I fired up the monthly dataviz stream and built one 😛`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`An entire dataviz from scratch. Data collection and all. Doing one of these epic streams every last Sunday of the month.`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`Article coming soon `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1156226388686327808/pu/img/g3WAWxZczpQE1Hfj.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1156226523403128833"
          }}>{`3:34:19 PM – 7/30/2019`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1156226523403128833",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`29`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1156226523403128833",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`4`}</a></div>{`
    `}</div></div>
    <p>{`Watch the stream here 👇`}</p>
    <lite-youtube {...{
      "videoid": "_Ja-mdt5tnQ",
      "videostartat": "0"
    }}></lite-youtube>
    <lite-youtube {...{
      "videoid": "9Ue_6kEOblU",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`It's a little long. Just over 5 hours. You might want to fast-forward a few
times, read this article instead. Think of it as a recap and full featured
tutorial.`}</p>
    <p>{`Next lastish-Sunday-of-the-month you can join live. It's great fun :)`}</p>
    <p>{`[convertkit]`}</p>
    <p>{`Here's how we approached this data visualization on the stream:`}</p>
    <ol>
      <li parentName="ol">{`Collect data`}</li>
      <li parentName="ol">{`See what we find`}</li>
      <li parentName="ol">{`Design a visualization`}</li>
      <li parentName="ol">{`Build with React & D3`}</li>
    </ol>
    <p>{`I'm not so good at design methodology so we're going to focus on building and
data collection. Design happened through trial and error and a few ideas in my
head.`}</p>
    <p>{`You can try it out live,
`}<a parentName="p" {...{
        "href": "https://newsletter-joy-dataviz-swizec.swizec-react-dataviz.now.sh"
      }}>{`here`}</a></p>
    <iframe src="https://newsletter-joy-dataviz-swizec.swizec-react-dataviz.now.sh" width="100%" height="500"></iframe>
    <p>{`Full
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactfordataviz/tree/master/newsletter-dataviz"
      }}>{`code on GitHub`}</a></p>
    <h2 {...{
      "id": "collecting-data"
    }}>{`Collecting data`}</h2>
    <p>{`Our data comes from 2 sources:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://convertkit.com/"
        }}>{`ConvertKit`}</a>{` for subscribers, emails, open rates,
etc.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.typeform.com/"
        }}>{`TypeForm`}</a>{` for sentiment about each email sent`}</li>
    </ol>
    <p>{`We never ended up using ConvertKit subscriber data so I'm not gonna talk about
downloading and anonymizing that. You can see it in the stream.`}</p>
    <p>{`ConvertKit and TypeForm APIs worked great for everything else.`}</p>
    <h3 {...{
      "id": "convertkit-broadcastsemails"
    }}>{`ConvertKit broadcasts/emails`}</h3>
    <p>{`ConvertKit calls the emails that you manually send to your subscribers
broadcasts. There's no built-in export for broadcast data so we used the API.`}</p>
    <p>{`Since there's no ConvertKit library I could find, we built our own following
`}<a parentName="p" {...{
        "href": "https://developers.convertkit.com/#broadcasts"
      }}>{`the docs`}</a>{`. A few `}<inlineCode parentName="p">{`fetch()`}</inlineCode>{`
calls and some JavaScript glue code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const fetch = require('node-fetch')
const { CK_KEY } = require('./secrets.json')
const fs = require('fs')

async function getBroadcasts() {
  const page1 = await fetch(
    \`https://api.convertkit.com/v3/broadcasts?page=1&api_secret=\${CK_KEY}\`
  ).then(res => res.json())
  const page2 = await fetch(
    \`https://api.convertkit.com/v3/broadcasts?page=2&api_secret=\${CK_KEY}\`
  ).then(res => res.json())

  const broadcasts = [...page1.broadcasts, ...page2.broadcasts]

  const result = []

  for (let broadcast of broadcasts) {
    const stats = await fetch(
      \`https://api.convertkit.com/v3/broadcasts/\${
        broadcast.id
      }/stats?api_secret=\${CK_KEY}\`
    ).then(res => res.json())

    result.push({
      ...broadcast,
      ...stats.broadcast.stats,
    })
  }

  fs.writeFileSync('public/data/broadcasts.json', JSON.stringify(result))
}

getBroadcasts()
`}</code></pre>
    <p>{`We make two API calls to get both pages of data. 50 results per page, just over
60 results in total. A real API wrapper would use some sort of loop here, but
for a quick hack this is fine.`}</p>
    <p>{`Then we take the list of broadcasts and fetch stats for each. API gives us the
subject line, number of sends, opens, clicks, stuff like that.`}</p>
    <p>{`We end up with a JSON file that contains all the email meta data we need for
our visualization.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[{
    "id": 2005225,
    "created_at": "2019-01-14T18:17:04.000Z",
    "subject": "A bunch of cool things and neat little tips",
    "recipients": 10060,
    "open_rate": 22.24652087475149,
    "click_rate": 4.473161033797217,
    "unsubscribes": 32,
    "total_clicks": 993,
    "show_total_clicks": true,
    "status": "completed",
    "progress": 100
},
`}</code></pre>
    <h3 {...{
      "id": "typeform"
    }}>{`TypeForm`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/oc3zSd7.png",
        "alt": null
      }}></img></p>
    <p>{`TypeForm data is best scraped with their API. They support CSV exports but
those work one by one. Manually going through all 60-some forms would take too
long.`}</p>
    <p>{`Scraping was pretty easy though – there's an official JavaScript API client :)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// scrape_typeform.js

const { createClient } = require("@typeform/api-client");
const fs = require("fs");

const typeformAPI = createClient({
    token: <API token>
});
`}</code></pre>
    <p>{`Those few lines of code give us an API client. Documentation is a little weird
and you have to guess some naming conventions from the actual API docs, but we
made it work.`}</p>
    <p>{`Fetching data happens in 3 steps:`}</p>
    <ol>
      <li parentName="ol">{`Get list of workspaces, that's what TypeForm calls groups of forms`}</li>
      <li parentName="ol">{`Get forms from all workspaces`}</li>
      <li parentName="ol">{`Get responses to each form`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// scrape_typeform.js

async function scrapeData() {
  // fetches workspaces and filters the 2 we need
  const workspaces = await typeformAPI.workspaces
    .list({
      pageSize: 200,
    })
    .then(res =>
      res.items.filter(({ name }) => ['Post Emails', 'Emails'].includes(name))
    )

  // fires parallel requests to fetch forms for each workspace
  // Promise.all waits for every request to finish
  const allForms = await Promise.all(
    workspaces.map(({ id }) =>
      typeformAPI.forms
        .list({ workspaceId: id, pageSize: 200 })
        .then(forms => forms.items)
    )
  )

  // flatten list of lists of forms into a single list
  // remove any forms that are older than my first ConvertKit email
  const forms = allForms
    .reduce((acc, arr) => [...acc, ...arr], []) // node 10 doesn't have .flat
    .filter(f => new Date(f.last_updated_at) > START_DATE)

  // use the same Promise.all trick to fire parallel response requests
  const responses = await Promise.all(
    forms.map(form =>
      typeformAPI.responses
        .list({ pageSize: 200, uid: form.id })
        .then(res => ({ form: form.id, responses: res.items }))
    )
  )

  // write forms and responses as JSON files
  fs.writeFileSync('public/data/forms.json', JSON.stringify(forms))
  fs.writeFileSync('public/data/responses.json', JSON.stringify(responses))
}
`}</code></pre>
    <p>{`A GraphQL API would make this much easier 😛`}</p>
    <p>{`Again, this isn't the prettiest code but it's meant to run once so no need to
make it perfect. If you wanted to maintain this long-term, I'd recommend
breaking each step into its own function.`}</p>
    <p>{`We end up with two JSON files containing all our sentiment data. The first
question, `}<em parentName="p">{`"Did you like this?"`}</em>{`, is numeric and easy to interpret. The rest
contain words so we won't use them for our dataviz ... altho it would be cool
to figure something out.`}</p>
    <h2 {...{
      "id": "setup-the-react-app"
    }}>{`Setup the React app`}</h2>
    <p>{`Ok now we've got our data, time to fire up a new create-react-app, load the
data, and start exploring.`}</p>
    <pre><code parentName="pre" {...{}}>{`$ create-react-app newsletter-dataviz
$ cd newsletter-dataviz
$ yarn add d3 react-use-dimensions styled-components
`}</code></pre>
    <p>{`We can work with a basic CRA app, no special requirements. Couple of
dependencies though:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`d3`}</inlineCode>{` gives us simple data loading functions and helpers for calculating
dataviz props`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react-use-dimensions`}</inlineCode>{` or `}<inlineCode parentName="li">{`useDimension`}</inlineCode>{` for short helps us make our dataviz
responsive`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`styled-components`}</inlineCode>{` is my favorite way to use CSS in React apps`}</li>
    </ul>
    <p>{`On the stream we did this part before scraping data so we had somewhere to
install dev dependencies. 😇`}</p>
    <h2 {...{
      "id": "load-data-in-the-app"
    }}>{`Load data in the app`}</h2>
    <p>{`We want to load our dataset asynchronously on component mount. Helps our app
load fast, tell the user data is loading, and make sure all the data is ready
before we start drawing.`}</p>
    <p>{`D3 comes with helpers for loading both CSV and JSON data so we don't have to
worry about parsing.`}</p>
    <p>{`A custom `}<inlineCode parentName="p">{`useDataset`}</inlineCode>{` hook helps us keep our code clean.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function useDataset() {
  const [broadcasts, setBroadcasts] = useState([])

  useEffect(() => {
    ;(async function() {
      // data loading and parsing stuff
    })()
  }, [])

  return { broadcasts }
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`useDataset`}</inlineCode>{` hook keeps one state variable: `}<inlineCode parentName="p">{`broadcasts`}</inlineCode>{`. We're going to
load all our data and combine it into a single data tree. Helps keep the rest
of our code simple.`}</p>
    <p>{`Loading happens in that `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, which runs our async function immediately
on component mount.`}</p>
    <h3 {...{
      "id": "load-broadcasts"
    }}>{`Load broadcasts`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
function useDataset() {
    // ...
    const broadcasts = await d3
        .json("data/broadcasts.json")
        .then(data =>
            data
                .map(d => ({
                    ...d,
                    created_at: new Date(d.created_at)
                }))
                .filter(d => d.recipients > 1000)
                .filter(d => d.status === "completed")
                .sort((a, b) => a.created_at - b.created_at)
        );
`}</code></pre>
    <p>{`Inside the effect we start with `}<inlineCode parentName="p">{`broadcasts`}</inlineCode>{` data.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`d3.json`}</inlineCode>{` to make a fetch request and parse JSON data into a JavaScript
object. `}<inlineCode parentName="p">{`.then`}</inlineCode>{` we iterate through the data and:`}</p>
    <ul>
      <li parentName="ul">{`change `}<inlineCode parentName="li">{`created_at`}</inlineCode>{` strings into `}<inlineCode parentName="li">{`Date`}</inlineCode>{` objects`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`filter`}</inlineCode>{` out any broadcasts smaller than 1000 recipients`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`filter`}</inlineCode>{` out any incomplete broadcasts`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sort`}</inlineCode>{` by `}<inlineCode parentName="li">{`created_at`}</inlineCode></li>
    </ul>
    <p>{`Always a good idea to perform all your data cleanup on load. Makes your other
code cleaner and you don't have to deal with strange edge cases.`}</p>
    <h3 {...{
      "id": "load-forms"
    }}>{`Load forms`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function useDataset() {
    // ...
    let forms = await d3.json("data/forms.json");

    // associate forms with their respective email
    const dateId = Object.fromEntries(
        broadcasts.map(d => [dateFormat(d.created_at), d.id])
    );

    forms = Object.fromEntries(
        forms.map(form => [
            form.id,
            dateId[dateFormat(new Date(form.last_updated_at))]
        ])
    );
`}</code></pre>
    <p>{`Then we load the forms data using `}<inlineCode parentName="p">{`d3.json`}</inlineCode>{` again.`}</p>
    <p>{`This time we want to associate each form with its respective email based on
date. This approach works because I usually create the email and the form on
the same day.`}</p>
    <p>{`We make heavy use of the `}<inlineCode parentName="p">{`fromEntries`}</inlineCode>{` method. It takes lists `}<inlineCode parentName="p">{`[key, value]`}</inlineCode>{`
pairs and turns them into `}<inlineCode parentName="p">{`key: value`}</inlineCode>{` objects.`}</p>
    <p>{`We end up with an object like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    dtnMgo: 2710510,
    G72ihG: 2694018,
    M6iSEQ: 2685890
        // ...
`}</code></pre>
    <p>{`Form id mapping to email id.`}</p>
    <h3 {...{
      "id": "load-responses"
    }}>{`Load responses`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function useDataset() {
    // ...
    let responses = await d3.json("data/responses.json");
    responses = responses
        .map(row => ({
            ...row,
            broadcast_id: forms[row.form]
        }))
        .filter(d => d.broadcast_id !== undefined);

    setBroadcasts(
        broadcasts.map(d => ({
            ...d,
            responses: responses.find(r => r.broadcast_id === d.id)
        }))
    );
`}</code></pre>
    <p>{`Finally we load our sentiment data – `}<inlineCode parentName="p">{`responses.json`}</inlineCode>{`.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`d3.json`}</inlineCode>{` to get all responses, add a `}<inlineCode parentName="p">{`broadcast_id`}</inlineCode>{` to each based on the
forms object, filter out anything with an undefined broadcast. Guess the "email
and broadcast on the same day" rule isn't perfect. 🤷‍♂️`}</p>
    <p>{`While saving data in local state with `}<inlineCode parentName="p">{`setBroadcasts`}</inlineCode>{`, we also map through
every entry and `}<inlineCode parentName="p">{`.find`}</inlineCode>{` relevant responses. When we're done React re-renders
our app.`}</p>
    <h2 {...{
      "id": "simplest-way-to-show-a-loading-screen"
    }}>{`Simplest way to show a Loading screen`}</h2>
    <p>{`Since we don't want users to stare at a blank screen while data loads, we
create the simplest of loading screens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function App() {
    const { broadcasts } = useDataset();

    if (broadcasts.length < 1) {
        return <p>Loading data ...</p>;
    }

    // ...
`}</code></pre>
    <p>{`Fire up the `}<inlineCode parentName="p">{`useDataset`}</inlineCode>{` hook, take broadcasts data out, see if there's
anything yet. If there isn't render a `}<inlineCode parentName="p">{`Loading data ...`}</inlineCode>{` text.`}</p>
    <p>{`That is all ✌️`}</p>
    <p>{`Since we're using a return, we'll have to make sure we add all hooks before
this part of the function. Otherwise you fall into conditional rendering and
hooks get confused. They have to be in the same order, always.`}</p>
    <h2 {...{
      "id": "responsively-render-emails-on-a-timeline"
    }}>{`Responsively render emails on a timeline`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/UV2TzvQ.png",
        "alt": null
      }}></img></p>
    <p>{`We render emails on a timeline with a combination of D3 scales and React
rendering loops. Each `}<inlineCode parentName="p">{`💌`}</inlineCode>{` emoji represents a single email. Its size shows the
open rate.`}</p>
    <p>{`Responsiveness comes from dynamically recalculating D3 scales based on the size
of our SVG element with the
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/useDimensions"
      }}><inlineCode parentName="a">{`useDimensions`}</inlineCode></a>{` hook.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function App() {
    const { broadcasts } = useDataset();
    const [ref, { width, height }] = useDimensions();

    // ...

    const xScale = d3
        .scaleTime()
        .domain(d3.extent(broadcasts, d => d.created_at))
        .range([30, width - 30]);

    const sizeScale = d3
        .scaleLinear()
        .domain(d3.extent(broadcasts, d => d.open_rate))
        .range([2, 25]);

    return (
        <svg ref={ref} width="99vw" height="99vh">
            {width &&
                height &&
                broadcasts
                    .map((d, i) => (
                        <Broadcast
                            key={d.id}
                            x={xScale(d.created_at)}
                            y={height / 2}
                            size={sizeScale(d.open_rate)}
                            data={d}
                        />
                    ))}
        </svg>
`}</code></pre>
    <p>{`A couple steps going on here 👇`}</p>
    <ol>
      <li parentName="ol">{`Get `}<inlineCode parentName="li">{`ref`}</inlineCode>{`, `}<inlineCode parentName="li">{`width`}</inlineCode>{`, and `}<inlineCode parentName="li">{`height`}</inlineCode>{`, from `}<inlineCode parentName="li">{`useDimensions`}</inlineCode>{`. The ref we'll use to
specify what we're measuring. Width and height will update dynamically as
the element's size changes on scroll or screen resize.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`xScale`}</inlineCode>{` is a D3 scale that maps `}<inlineCode parentName="li">{`created_at`}</inlineCode>{` dates from our dataset to
pixel values between `}<inlineCode parentName="li">{`30`}</inlineCode>{` and `}<inlineCode parentName="li">{`width-30`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`sizeScale`}</inlineCode>{` maps open rates from our dataset to pixel values between `}<inlineCode parentName="li">{`2`}</inlineCode>{` and
`}<inlineCode parentName="li">{`25`}</inlineCode></li>
      <li parentName="ol">{`Render an `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` element with the `}<inlineCode parentName="li">{`ref`}</inlineCode>{` from useDimensions. Use width and
height properties to make it full screen. When the browser resizes, this
element will resize, useDimensions will pick up on that, update our `}<inlineCode parentName="li">{`width`}</inlineCode>{`
and `}<inlineCode parentName="li">{`height`}</inlineCode>{`, trigger a re-render, and our dataviz becomes responsive 🤘`}</li>
      <li parentName="ol">{`When all values are available `}<inlineCode parentName="li">{`.map`}</inlineCode>{` through broadcast data and render a
`}<inlineCode parentName="li">{`<Broadcast>`}</inlineCode>{` component for each`}</li>
    </ol>
    <h3 {...{
      "id": "broadcast-component"
    }}><inlineCode parentName="h3">{`<Broadcast>`}</inlineCode>{` component`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<Broadcast>`}</inlineCode>{` component takes care of rendering and styling each letter
emoji on our visualization. Later it's going to deal with dropping hearts as
well.`}</p>
    <p>{`We start with a `}<inlineCode parentName="p">{`<CenteredText>`}</inlineCode>{` styled component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const CenteredText = styled.text\`
  text-anchor: middle;
  dominant-baseline: central;
\`
`}</code></pre>
    <p>{`Takes care of centering SVG text elements horizontally and vertically. Makes
positioning much easier.`}</p>
    <p>{`Right now the `}<inlineCode parentName="p">{`<Broadcast>`}</inlineCode>{` component just renders that.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Broadcast = ({ x, y, size, data }) => {
  return (
    <g transform={\`translate(\${x}, \${y})\`} style={{ cursor: 'pointer' }}>
      <CenteredText x={0} y={0} fontSize={\`\${size}pt\`}>
        💌
      </CenteredText>
    </g>
  )
}
`}</code></pre>
    <p>{`Render a grouping element, `}<inlineCode parentName="p">{`<g>`}</inlineCode>{`, use an SVG transform to position at `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{`
coordinates, and render a `}<inlineCode parentName="p">{`<CenteredText>`}</inlineCode>{` with a `}<inlineCode parentName="p">{`💌`}</inlineCode>{` emoji using the `}<inlineCode parentName="p">{`size`}</inlineCode>{`
prop for font size.`}</p>
    <p>{`The result is a responsive timeline.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/Z6MC3tV.gif",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "animate-the-timeline"
    }}>{`Animate the timeline`}</h2>
    <p>{`Animating the timeline is a sort of trick 👉 change `}<inlineCode parentName="p">{`N`}</inlineCode>{` of rendered emails over
time and you get an animation.`}</p>
    <p>{`We create a `}<inlineCode parentName="p">{`useRevealAnimation`}</inlineCode>{` React hook to help us out.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function useRevealAnimation({ duration, broadcasts }) {
  const [N, setN] = useState(0)

  useEffect(() => {
    if (broadcasts.length > 1) {
      d3.selection()
        .transition('data-reveal')
        .duration(duration * 1000)
        .tween('Nvisible', () => {
          const interpolate = d3.interpolate(0, broadcasts.length)
          return t => setN(Math.round(interpolate(t)))
        })
    }
  }, [broadcasts.length])

  return N
}
`}</code></pre>
    <p>{`We've got a local state for `}<inlineCode parentName="p">{`N`}</inlineCode>{` and a `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` to start the animation. The
effect starts a new D3 transition, sets up a custom tween with an interpolator
from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`broadcasts.length`}</inlineCode>{` and runs `}<inlineCode parentName="p">{`setN`}</inlineCode>{` with a new number on every tick
of the animation.`}</p>
    <p>{`D3 handles the heavy lifting of figuring out exactly how to change `}<inlineCode parentName="p">{`N`}</inlineCode>{` to
create a nice smooth animation.`}</p>
    <p>{`I teach this approach in more detail as hybrid animation in my
`}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for DataViz`}</a>{` course.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`useRevealAnimation`}</inlineCode>{` hook goes in our `}<inlineCode parentName="p">{`App`}</inlineCode>{` component like this 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

function App() {
    const { broadcasts } = useDataset();
    const [ref, { width, height }] = useDimensions();
    const N = useRevealAnimation({ broadcasts, duration: 10 });

    // ...

            {width &&
                height &&
                broadcasts
                    .slice(0, N)
                    .map((d, i) => (
                        <Broadcast
`}</code></pre>
    <p><inlineCode parentName="p">{`N`}</inlineCode>{` updates as the animation runs and `}<inlineCode parentName="p">{`broadcasts.slice`}</inlineCode>{` ensures we render only
the first `}<inlineCode parentName="p">{`N`}</inlineCode>{` elements of our data. React's diffing engine figures out the rest
so existing items don't re-render.`}</p>
    <p>{`This avoid-re-rendering part is very important to create a smooth animation of
dropping hearts.`}</p>
    <h2 {...{
      "id": "add-dropping-hearts"
    }}>{`Add dropping hearts`}</h2>
    <p>{`Each `}<inlineCode parentName="p">{`<Broadcast>`}</inlineCode>{` handles its own dropping hearts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Broadcast.js

const Broadcast = ({ x, y, size, data, onMouseOver }) => {
  const responses = data.responses ? data.responses.responses : []

  // ratings > 3 are a heart, probably
  const hearts = responses
    .map(r => (r.answers ? r.answers.filter(a => a.type === 'number') : []))
    .flat()
    .filter(({ number }) => number > 3).length

  return (
    <g
      transform={\`translate(\${x}, \${y})\`}
      onMouseOver={onMouseOver}
      style={{ cursor: 'pointer' }}
    >
      // ..
      <Hearts hearts={hearts} bid={data.id} height={y - 10} />
    </g>
  )
}
`}</code></pre>
    <p>{`Get a list of `}<inlineCode parentName="p">{`responses`}</inlineCode>{` out of data associated with each broadcast, flatten
into a simple array, and filter out any votes below `}<inlineCode parentName="p">{`3`}</inlineCode>{` on the
`}<inlineCode parentName="p">{`0, 1, 2, 3, 4, 5`}</inlineCode>{` scale. Assuming high numbers mean `}<em parentName="p">{`"I liked this"`}</em>{`.`}</p>
    <p>{`Render with a `}<inlineCode parentName="p">{`<Hearts>`}</inlineCode>{` component.`}</p>
    <h3 {...{
      "id": "hearts-component"
    }}><inlineCode parentName="h3">{`<Hearts>`}</inlineCode>{` component`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<Hearts>`}</inlineCode>{` component is a simple loop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Broadcast.js

const Hearts = ({ bid, hearts, height }) => {
  return (
    <>
      {d3.range(0, hearts).map(i => (
        <Heart
          key={i}
          index={i}
          id={\`\${bid}-\${i}\`}
          height={height - i * 10}
          dropDuration={3}
        />
      ))}
    </>
  )
}
`}</code></pre>
    <p>{`Create a counting array with `}<inlineCode parentName="p">{`d3.range`}</inlineCode>{`, iterate over it, and render a
`}<inlineCode parentName="p">{`<Heart>`}</inlineCode>{` for each. The `}<inlineCode parentName="p">{`<Heart>`}</inlineCode>{` component declaratively takes care of
rendering itself so it drops into the right place.`}</p>
    <h3 {...{
      "id": "heart-component"
    }}>{`<`}<inlineCode parentName="h3">{`Heart`}</inlineCode>{`> component`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Heart = ({ index, height, id, dropDuration }) => {
  const y = useDropAnimation({
    id,
    duration: dropDuration,
    height: height,
    delay: index * 100 + Math.random() * 75,
  })

  return (
    <CenteredText x={0} y={y} fontSize="12px">
      ❤️
    </CenteredText>
  )
}
`}</code></pre>
    <p>{`Look at that, another animation hook. Hooks really simplify our code 🥰`}</p>
    <p>{`The animation hook gives us a `}<inlineCode parentName="p">{`y`}</inlineCode>{` coordinate. When that changes, the component
re-renders, and re-positions itself on the page.`}</p>
    <p>{`That's because `}<inlineCode parentName="p">{`y`}</inlineCode>{` is handled as a React state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Broadcast.js

function useDropAnimation({ duration, height, id, delay }) {
  const [y, sety] = useState(0)

  useEffect(() => {
    d3.selection()
      .transition(\`drop-anim-\${id}\`)
      .ease(d3.easeCubicInOut)
      .duration(duration * 1000)
      .delay(delay)
      .tween(\`drop-tween-\${id}\`, () => {
        const interpolate = d3.interpolate(0, height)
        return t => sety(interpolate(t))
      })
  }, [])

  return y
}
`}</code></pre>
    <p>{`We're using the same hybrid animation trick as before except now we added an
easing function to our D3 transition so it looks better.`}</p>
    <p>{`The result are hearts dropping from an animated timeline.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/iO6X09T.gif",
        "alt": null
      }}></img></p>
    <p>{`[convertkit]`}</p>
    <h2 {...{
      "id": "add-helpful-titles"
    }}>{`Add helpful titles`}</h2>
    <p>{`Last feature that makes our visualization useful are the titles. They create
context and tell users what they're looking at.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/Le0Hj1M.png",
        "alt": null
      }}></img></p>
    <p>{`No dataviz trickery here, just helpful info in text form :)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Heading = styled.text\`
  font-size: 1.5em;
  font-weight: bold;
  text-anchor: middle;
\`

const MetaData = ({ broadcast, x }) => {
  if (!broadcast) return null

  // count likes
  // math the ratios for opens, clicks, etc

  return (
    <>
      <Heading x={x} y={50}>
        {broadcast ? dateFormat(broadcast.created_at) : null}
      </Heading>
      <Heading x={x} y={75}>
        {broadcast ? broadcast.subject : null}
      </Heading>
      <text x={x} y={100} textAnchor="middle">
        ❤️ {heartRatio.toFixed(0)}% likes 📖 {broadcast.open_rate.toFixed(0)}%
        reads 👆 {broadcast.click_rate.toFixed(0)}% clicks 😢{' '}
        {unsubRatio.toFixed(2)}% unsubs
      </text>
    </>
  )
}
`}</code></pre>
    <p>{`We use some middle school maths to calculate the ratios we're showing, then
render a `}<inlineCode parentName="p">{`<Heading>`}</inlineCode>{` styled component twice and a `}<inlineCode parentName="p">{`<text>`}</inlineCode>{` component once.`}</p>
    <p>{`Headings show the email date and title, text shows meta info about open rates
and such. Nothing fancy, but it makes the data visualization a lot better I
think.`}</p>
    <h2 {...{
      "id": "️"
    }}>{`❤️`}</h2>
    <iframe src="https://newsletter-joy-dataviz-swizec.swizec-react-dataviz.now.sh" width="100%" height="500"></iframe>
    <p>{`And so we end up with a nice dataviz full of hearts and emojis and transitions
and animation. Great way to see which emails sparked joy 😍`}</p>
    <p>{`Next step could be some sort of text analysis and figuring out which topics or
words correlate to more enjoyment. Could be fun but I don't think we have a big
enough dataset for proper sentiment analysis.`}</p>
    <p>{`Maybe 🤔`}</p>
    <p>{`Thanks for reading, ~Swizec`}</p>
    <p><em parentName="p">{`See a mistake?
`}<a parentName="em" {...{
          "href": "https://github.com/Swizec/reactd3lander/tree/master/content/article"
        }}>{`Suggest an edit`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      